@import "src/assets/styles/breakpoints";

.loginLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 15px;
  @include screen_down_599 {
    padding-top: 30px;
  }
  .loginLayout__content {
    max-width: 1100px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px #000;
    display: flex;
    min-height: 500px;
    @include screen_down_599 {
      flex-direction: column;
    }
    .loginLayout__formBox {
      width: 100%;
      padding: 50px 30px;
      border-radius: 15px 0 0 15px;
      position: relative;
      background: var(--tablesBg);
      @include screen_down_599 {
        border-radius: 15px 15px 0 0;
        padding-top: 20px;
      }
      .loginLayout__icon {
        position: absolute;
        top: -43px;
        left: 26px;
        width: 86px;
        height: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 15px 15px 15px;
        background: var(--secondary);
        box-shadow: 0 -5px 15px #000;
        @include screen_down_599 {
          width: 60px;
          height: 60px;
          top: -30px;
        }
        svg {
          font-size: 3rem;
          @include screen_down_599 {
            font-size: 2rem;
          }
        }
      }
      .loginLayout__enter {
        font-family: var(--RobotoSlab);
        color: var(--grey);
        font-size: 24px;
        text-transform: uppercase;
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        margin: 20px 0 50px;
        @include screen_down_599 {
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
      }
      .loginLayout__form {
        display: flex;
        flex-direction: column;
        .loginLayout__input {
          margin-bottom: 30px;
          @include screen_down_599 {
            margin-bottom: 10px;
          }
        }
        button {
          min-height: 56px;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    .loginLayout__bg {
      width: 100%;
      background: url("../../assets/img/login_bg.jpg") no-repeat center top/cover;
      border-radius: 0 15px 15px 0;
      overflow: hidden;
      @include screen_down_599 {
        border-radius: 0 0 15px 15px;
        flex-grow: 1;
      }
      .loginLayout__logo {
        max-width: 260px;
        padding: 20px 0 0 20px;
      }
    }
  }
}
