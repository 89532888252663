@import "./variables.scss";

// XS
@mixin screen_up_0 {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}
@mixin screen_down_599 {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// SM
@mixin screen_up_600 {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin screen_down_959 {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// MD
@mixin screen_up_960 {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin screen_down_1279 {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// LG
@mixin screen_up_1280 {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin screen_down_1919 {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// XL
@mixin screen_up_1920 {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin screen_up_custom($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}
@mixin screen_down_custom($screen) {
  @media (max-width: $screen+"px") {
    @content;
  }
}
